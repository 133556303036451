import { Injectable, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { initializePipelineSelection, subscriptionsGet, subscriptionsLoad } from '../actions/subscriptions.actions';
import { PipelineService } from '../../services/pipeline.service';
import { CacheService } from '../../services/cache.service';
import { modalsClose, modalsUpdate } from '../actions/modals.action';
import { intercomUpdate } from '../actions/intercom.actions';
import { environment } from 'src/environments/environment';
import { JobsService } from 'src/app/modules/jobs/jobs.service';
import { delay } from '../../functions/utility';

@Injectable()
export class SubscriptionsEffects implements OnInit {
  cacheKey: string;
  subscriptionsObservable$: any;
  subscriptionsGet$ = createEffect(() => this.actions$
    .pipe(
      ofType(subscriptionsGet),
      tap(async action => {
        try {

          this.store$.dispatch(modalsUpdate({
            modalType: 'progress',
            title: 'Account',
            message: 'Retrieving subscriptions...',
            progress: 75
          }));

          const effectSelf = this; // because, we need "this" somewhere else.
          let subscriptions = this.cacheService.getCache('local', action.subscriptionCacheKey);

          subscriptions = JSON.parse(subscriptions);

          if(subscriptions?.subscriptions) {
            let cachedKeyCount = null;

            if(subscriptions.subscriptions.length > 0) {
              cachedKeyCount =  Object.keys(subscriptions.subscriptions[0]).length;
            }

            if(cachedKeyCount !== null && cachedKeyCount !== environment.cache.subscriptionsExpectedKeyCount) {
              console.info("Expected keycount different, deleting subscription cache", environment.cache.subscriptionsExpectedKeyCount, cachedKeyCount);
              this.cacheService.removeCache('local', action.subscriptionCacheKey);
              subscriptions = this.cacheService.getCache('local', action.subscriptionCacheKey);
              subscriptions = JSON.parse(subscriptions);
            }
          }

          if (subscriptions === null || typeof subscriptions === 'undefined') {
            console.info('Requesting pipeline subscription data from openbridge API');

            const subscriptionList = [];
            const subscriptionsResponses = await this.pipelineService.getAll();

            for (const response of subscriptionsResponses) {
              const included = response['included'];
              const data = response['data'];

              for (const pipeline of data) {
                const formatted = effectSelf.pipelineService.transformSubscriptionResponse(pipeline, included);
                if (formatted) {
                  subscriptionList.push(formatted);
                }
              }
            }

            subscriptions = { subscriptions: subscriptionList };
          }

          const subscriptionKeys = Object.keys(subscriptions);
          if(!subscriptionKeys.includes('subscriptions')) {
            subscriptions['subscriptions'] = [];
          }

          // update product ids in intercom
          const allSubscriptions = subscriptions.subscriptions;
          const activeSubscription = allSubscriptions.filter(data => data.status === 'active');
          const cancelledSubscription = allSubscriptions.filter(data => data.status === 'cancelled')

          const activeSubscriptionProductIdMap = activeSubscription.map(data => (data.productId));
          const activeSubscriptionUniqueProductIds = this.removeDuplicateProductIds(activeSubscriptionProductIdMap);

          const cancelledSubscriptionProductIdMap = cancelledSubscription.map(data => (data.productId));
          const cancelledSubscriptionUniqueProductIds = this.removeDuplicateProductIds(cancelledSubscriptionProductIdMap);

          this.store$.dispatch(intercomUpdate({
            ActiveSubscribedProductIds: activeSubscriptionUniqueProductIds,
            InActiveSubscribedProductIds: cancelledSubscriptionUniqueProductIds
          }));

          this.store$.dispatch(modalsUpdate({
            modalType: 'progress',
            title: 'Account',
            message: 'Retrieving subscriptions state for those flagged as having history runs processing',
            progress: 75
          }));

          await delay(600);


          for (const { index, value } of subscriptions.subscriptions.map((value, index) => ({ index, value }))) {

            if(value.history !== false) {
              try {
                const response = await this.jobsService.getActiveHistoryJobs(value.id);
                if(response.data.length === 0) {
                  console.info(`History for subscription ${value.id} completed, clearing flag.`)
                  subscriptions.subscriptions[index].history = false;
                }
              }
              catch (error) {
                console.error("An error occurred trying to retrieve job history data.");
              }
            }
          }

          // load subscriptions
          this.store$.dispatch(subscriptionsLoad(subscriptions));

          // initially uncheck all the pipelines
          if (subscriptions !== null && typeof subscriptions !== 'undefined') {
            const basePipelines = subscriptions.subscriptions.filter(val => val.status !== 'invalid');
            const uncheckAllPipelines = basePipelines.map(item => ({ id: item.id, isSelected: false }));
            this.store$.dispatch(initializePipelineSelection(
              {
                selectedPipelines: uncheckAllPipelines
              }
            ));
          }
          this.store$.dispatch(modalsClose());
        }
        catch (error) {
          this.store$.dispatch(modalsClose());
          throw error;
        }
      })
    ),
    { dispatch: false }
  );

  ngOnInit(): void {

  }

  constructor(
    private actions$: Actions,
    private pipelineService: PipelineService,
    private store$: Store<AppState>,
    private cacheService: CacheService,
    private jobsService: JobsService
  ) { }

  private removeDuplicateProductIds(data): any {
    return data.filter((value, index) => data.indexOf(value) === index);
  }

}
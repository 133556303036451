export const productIdStubProduct = 0;

/**
 * Create constants for all the destination product IDs.
 */
export const productIdAwsAthena = 31;
export const productIdAwsRedshift = 29;
export const productIdAwsRedshiftManaged = 14;
export const productIdAwsRedshiftSpectrum = 36;
export const productIdSnowflakeWarehouse = 52;
export const productIdGoogleBigquery = 37;
export const productIdAzureDataLake = 47;
export const productIdAzureBlobStorage = 46;
export const productIdDataBricks = 68;
export const productIdDataBricksExternal = 73;
export const productIdMultiStorage = 71;

/**
 * Create constants for all the source product IDs.
 */
export const productIdAmazonSponsoredAdsV2 = 40;
export const productIdAmazonSponsoredAdsV3 = 70;
export const productIdAmazonAttribution = 50;
export const productIdAmazonDsp = 54;
export const productIdAmazonDspV3 = 85;
export const productIdAmazonAdvertisingBrandMetrics = 75;
export const productIdAmazonBrandStoreInsights = 83;
export const productIdAmazonAdvertisingMarketStream = 76;
export const productIdAmazonAdvertisingMarketStreamV2 = 87;
export const productIdAmazonAdvertisingRecommendations = 79;

export const productIdMwsAdvancedReporting = 39;
export const productIdMwsSettlementReports = 41;
export const productIdMwsRealtimeOrders = 42;
export const productIdMwsBasicReporting = 43;
export const productIdMwsWarehouse = 48;
export const productIdMwsFinances = 51;

export const productIdAmzPricingApi = 81;
export const productIdAmzCatalogApi = 82;

export const productIdAmzSellingPartnersOrders = 53;
export const productIdAmzSellingPartnersOrdersPii = 78;
export const productIdAmzSellingPartnersFinanceRt = 55;
export const productIdAmzSellingPartnersInbound = 56;
export const productIdAmzSellingPartnersInboundV2 = 84;
export const productIdAmzSellingPartnersSettlement = 57;

export const productIdAmzSellingBusRepFullfillment = 58;
export const productIdAmzSellingBusRepInventoryRt = 59;
export const productIdAmzSellingBusRepInventory = 60;
export const productIdAmzSellingBusRepSales = 61;
export const productIdAmzSellingBusRepFees = 62;
export const productIdAmzSellingPartnersSalesAndTraffic = 64;
export const productIdAmzSellingPartnersBrandAnalyticsReports = 65;

export const productIdAmzSellingPartnerNotifications = 86;

export const productIdAmzVendorsRetailAnalytics = 66;
export const productIdAmzVendorsRetailProcurmentOrder = 67;
export const productIdAmzVendorBrandAnalyticsReports = 69;
export const productIdAmzVendorRealtimeAnalytics = 72;

export const productIdFacebookMarketing = 11;
export const productIdFacebookPageInsights = 2;
export const productIdGoogleAds = 63;
export const productIdGoogleAnalytics360 = 3;
export const productIdGoogleCampaignManager = 4;
export const productIdGoogleSearchAds360 = 7;
export const productIdInstagramInsights = 34;
export const productIdInstagramStories = 38;
export const productIdShopifyInsights = 44;

export const productIdYoutubeChannelInsights = 17;
export const productIdYoutubeCompetitorChannels = 18;
export const productIdYoutubeVideoInsights = 26;
export const productIdYoutubeCompetitorVideo = 15;

export const productIdBatchSftpProcessing = 74;
export const productIdAwsKinesisWebhooks = 35;
export const productIdOpenbridgeAirbyte = 77;

export const productIdAwsS3Source = 80;